<template>
  <div class="wrapper">
    <p style="color: #666">更新日期：2024年1月1日</p>
    <p style="color: #666">生效日期：2024年1月1日</p>
    <p>
      【<b>特别提示</b>】
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </p>
    <p>
      1.请在使用本产品（或服务）前，仔细阅读并了解《隐私权政策》（以下简称“本隐私权政策”，尤其是加粗或划线的内容。<b>在确认充分理解并同意后使用我们的产品或服务。一旦您开始使用本公司的产品和服务，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、储存和分享您的相关信息。</b>阅读过程中，如您有任何疑问，可通过电话或电邮方式获得帮助。一旦您点击“同意”本隐私权政策，我们将视为您充分理解并同意本隐私权政策。
    </p>
    <p>
      2.【郑州云济信息科技有限公司】（以下简称“我们”“本公司”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
    </p>
    <p>
      3.为了向您提供优质服务，主要需要获取以下权限和信息。系统将弹框提示，建议您允许获取相关权限：
    </p>
    <p>获取设备信息权限。</p>
    <p>当您登录本软件时，我们会收集设备信息用于硬件绑定。</p>
    <p>
      获取启用存储读取权限。用于使用本软件服务中，将浏览图片或者文字信息保存至计算机存储中。如您拒绝授权，您将无法保存图片或者文字信息，但不影响您正常使用其他功能。
    </p>
    <p>
      获取定位权限。用于使用本软件服务中，将影响打卡功能。如您拒绝授权，您将无法打卡以及使用地图，但不影响您正常使用其他功能。
    </p>
    <p>其他功能。</p>
    <p>
      4.使用本软件服务过程中，还将可能使用其他权限，详见本软件服务隐私政策。
    </p>
    <p><!--[if-->5.&nbsp;<!--[endif]-->本隐私权政策将帮助您了解以下内容：</p>
    <p>• 本协议的适用范围</p>
    <p>• 我们如何收集和使用您的个人信息</p>
    <p>• 我们如何使用Cookie和同类技术</p>
    <p>• 我们如何共享、转让、公开披露您的个人信息</p>
    <p>• 我们如何保护和保存您的个人信息</p>
    <p>• 您的权利</p>
    <p>• 我们如何处理未成年人的信息</p>
    <p>• 本隐私权政策如何更新</p>
    <p>• 您如何与我们取得联系</p>
    <p><b>条款正文</b><b></b></p>
    <p><b>一、本协议的使用范围</b><b></b></p>
    <p>
      （一）本隐私权政策适用于显示本隐私政策、或链接至本隐私政策的网站和产品、服务。服务包括向您提供页面浏览、登录服务，以及通过云济公司向您提供的产品服务。但本政策可能并不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息可能由云济公司在补充政策中，或者在收集数据时提供的通知中发布。
    </p>
    <p>
      （二）本隐私权政策不适用于其他第三方向您提供的服务。例如，云济公司平台上的服务商依托云济公司平台向您提供服务时，您向服务商提供的信息不适用本隐私权政策。我们对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容和服务。我们无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见这些第三方的隐私保护政策。
    </p>
    <p>
      （三）需要特别说明的是，本《隐私权政策》为统一适用的一般性隐私条款，本政策所述之“我们的产品与/或服务”以及我们收集的您个人信息的类型和对应的使用、处理规则等可能会因您使用的具体的产品/服务而有所不同，具体以您实际使用的产品和/或服务为准。该产品或服务的单独隐私政策未涵盖的部分，以本隐私政策内容为准。
    </p>
    <p><b>二</b><b>、我们如何收集和使用您的个人信息</b><b></b></p>
    <p>
      您同意，在下列环节向我们提供下列信息，并授权我们识别、记录、保存并在为您提供服务时使用下列信息。同时您授权我们可在需要第三方配合为您提供服务的时候向该第三方提供必要的信息。
    </p>
    <p><b>（一）登录</b><b></b></p>
    <p>在登录账号时</p>
    <p>
      您至少需要向我们提供以下信息：您本人的手机号码、用户名和密码。如果您在用户注册时不提供上述信息，您将无法享受我们提供的产品与/或服务。
    </p>
    <p>
      在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：工作职位、公司。因产品服务与实际使用相关联，若用户提供的信息不完整、不真实，则可能在使用过程中受限或无法使用部分功能。
    </p>
    <p><b>（二）页面内容展示</b><b></b></p>
    <p>
      除法律另有规定之外，我们将您本人提供给本软件的信息、因享受本软件服务而产生的信息（包括本协议签署之前提供和产生的信息）及之本软件根据本条约定查询、收集的信息，用于本软件及因服务必要而委托的合作伙伴为用户本人提供服务、推荐产品、开展市场调查与信息数据分析。
    </p>
    <p>
      在使用软件的过程中，我们可能会收集和处理有关您的计算机信息、位置信息、网络身份标识信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。我们会使用各种技术进行定位，这些技术包括IP地址等能够提供相关信息的其他传感器（比如可能会为我们提供附近设备、Wi-Fi
      接入点和基站的信息）。
    </p>
    
    <p><b>（</b><b>三</b><b>）问卷功能</b><b></b></p>
    <p>
      为保证用户能正常参与平台内的各项问卷调研，提交问卷结果，为此我们会收集用户的账号。
    </p>

    <p><b>（</b><b>四</b><b>）</b><b>评论及回复</b><b></b></p>
    <p>为保证用户能发帖、回帖中添加图片，我们会取得相机与相册的权限。</p>
    <p>
      为了保障您的帐号安全，我们需要您提供必要的个人信息以核验您的用户身份。
    </p>
    <p>
      为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括帐号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的帐号信息和订单信息。
    </p>
    <p>
      为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息。
    </p>
    <p><b>（</b><b>五</b><b>）</b><b>打卡</b><b></b></p>
    <p>
      当您需要打卡考勤时，我们会取得定位权限。
    </p>
    <p><b>（</b><b>六</b><b>）为您提供其他附加功能</b><b></b></p>
    <p>
      <b
        >在您使用软件服务过程中，为了保证您能正常使用产品功能，我们仅会在使用期间基于最大必要性以及最小范围的原则收集您的一些信息，并向系统申请您的以下设备权限，申请前我们会征询您的同意，您可以选择“允许”或“禁止”权限申请；申请成功后，您可以随时进入计算机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使</b
      ><b></b>
    </p>
    <p><b>这些附加服务包括：</b><b></b></p>
    <p>1.个性化内容服务</p>
    <p>
      我们会根据您的软件使用情况对您进行定制化的内容服务，例如为您推荐适合您的商品或服务等，为此，我们需要获取您的账号、软件使用记录。若您不同意使用个性化内容推荐，您可以将此功能关闭。
    </p>
    <p>2.数据统计与产品优化</p>
    <p>
      为了通过数据统计对产品进行优化，我们只在每次启动时收集一次您的设备MAC地址，唯一设备识别码、广告追踪标识符（IDFA）、账号、性别、出生年份、学历、行业、职业、软件使用记录、点击记录、IP地址。
    </p>
    <p>3.基于相机、相册权限的附加服务</p>
    <p>
      您可以在开启相机、相册权限后使用该功能，以实现头像上传、意见反馈、获取人脸信息。
    </p>
    <p>4.基于麦克风权限的附加服务</p>
    <p>
      我们仅在讲师使用直播过程中，通过开启麦克风权限以实现在在线直播界面发送语音进行交流、答疑，学员可以进行连麦与讲师进行语音交流，可以使用语音转文字功能，减少手动输入操作，提升体验。
    </p>
    <p>5.基于位置定位的附加功能</p>
    <p>
      我们仅在您使用面授班扫码地理位置签到功能时，向您的计算机系统申请“位置权限”。
    </p>
    <p>6. 基于获取设备信息权限的附加功能</p>
    <p>
      为了您的账号安全，我们只在每次启动时收集一次您的硬件设备信息，并与系统上的设备信息进行匹配，从而评估您的账号登录是否存在风险。在此过程中，我们会向系统申请“获取设备信息权限”。
    </p>
    <p>
      为了给您提供更优质的服务，我们可能会根据产品功能向系统申请您的其他设备权限，如果您拒绝开启相应设备权限，您可以随时进入计算机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用，请您理解。
    </p>
    <p><b>（</b><b>七</b><b>）与您沟通</b><b></b></p>
    <p>
      我们会利用收集的信息（例如您的计算机号码、电子邮件地址）直接与您沟通或互动。例如，如果我们检测到账号异常登录活动。
    </p>
    <p><b>（</b><b>八</b><b>）改善和运营本产品和服务</b><b></b></p>
    <p>
      我们使用收集的信息来提供并改进我们及关联方、合作方提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。
    </p>
    <p>
      为改进我们及关联方、合作方的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，可能会与关联方、合作方、公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
    </p>
    <p><b>（</b><b>九</b><b>）</b><b>您个人信息使用的规则</b><b></b></p>
    <p>
      1.我们会根据本隐私政策的规定，为实现我们的产品与/或服务功能而对所收集的个人信息进行使用。
    </p>
    <p>
      2.在收集您的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据进行分析并予以利用。
    </p>
    <p>
      3.请您了解并统一，对于您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将被视为您在使用的产品与/或服务期间持续授权同意我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。
    </p>
    <p>
      4.我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
    </p>
    <p>
      5.当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
    </p>
    <p>
      6.当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的个人信息用于其他目的时，会事先征求您的明示同意。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
    </p>
    <p><b>二、我们如何使用 Cookie 和同类技术</b><b></b></p>
    <p><b>（一）Cookie</b><b></b></p>
    <p>
      为确保网站正常运转，我们会在您的计算机上存储名为 Cookie
      的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。
    </p>
    <p>
      我们不会将 Cookie
      用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
    </p>
    <p><b>（二）网站信标和像素标签</b><b></b></p>
    <p>
      除Cookie外，我们还会在网站上使用网站信标和像素标签其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
    </p>
    <p><b>三、我们如何共享、转让、公开披露您的个人信息</b><b></b></p>
    <p><b>（一）共享</b><b></b></p>
    <p>
      <b
        >您确认，您授权本公司，除法律另有规定之外，将您提供给本公司的信息、享受本公司服务产生的信息（包括本条款签署之前提供和产生的信息）以及本公司根据本条款约定查询、收集的信息，用于本公司及其因服务必要委托的合作伙伴为您提供服务、推荐产品、开展市场调查与信息数据分析</b
      ><b
        >，包括但不限于使用用户提供的信息，通过人社数据指定端口查询用户社保数据用于数据比对等</b
      ><b
        >。您授权本公司，除法律另有规定之外，基于为您提供更优质服务和产品的目的，向本公司因服务必要开展合作的伙伴提供、查询、收集您的信息。</b
      >前述“信息”可能包括但不限于您的身份信息、账户信息、信用信息、交易信息、计算机信息、计算机操作日志及其他与您相关的信息。
    </p>
    <p>
      为确保您信息的安全，本公司对上述信息负有保密义务，并尽最大努力采取各种措施保证信息安全。本条款自您签署时生效，具有独立法律效力，不受本条款所属合同或协议成立与否及效力状态变化的影响。
    </p>
    <p><b>（二）转让</b><b></b></p>
    <p>
      我们不会将您的个人信息转让给除关联方外的任何公司、组织和个人，但以下情况除外：
    </p>
    <p>
      <b
        >1.在事先获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息</b
      >。
    </p>
    <p>
      <b>2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</b><b></b>
    </p>
    <p>
      <b>3</b>.<b
        >在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</b
      ><b></b>
    </p>
    <p><b>（三）公开披露</b><b></b></p>
    <p>
      原则上我们不会将您的信息进行公开披露。如确需公开披露，我们会向您告知公开披露的目的，披露信息的类型以及可能涉及的敏感信息，并征得您明确同意。
    </p>
    <p>
      根据相关法律法规和国家标准，<b>在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息而无需征得您的同意</b>：
    </p>
    <p><b>1.与国家安全、国防安全直接相关的；</b><b></b></p>
    <p><b>2.与公共安全、公共卫生、重大公共利益直接相关的；</b><b></b></p>
    <p><b>3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</b><b></b></p>
    <p>
      <b
        >4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</b
      ><b></b>
    </p>
    <p><b>5.您自行向社会公众公开的个人信息；</b><b></b></p>
    <p>
      <b
        >6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</b
      ><b></b>
    </p>
    <p><b>7.根据个人信息主体要求签订和履行合同所必需的；</b><b></b></p>
    <p><b>8.用于维护所提供的服务的安全稳定运行所必须的。</b><b></b></p>
    <p>
      根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </p>
    <p><b>四、我们如何保护和保存您的个人信息</b><b></b></p>
    <p><b>（一）保存期限</b><b></b></p>
    <p>
      <b>您在使用本产品及服务期间，我们将持续为您保存您的个人信息</b
      >。如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。
    </p>
    <p><b>（二）保存地域</b><b></b></p>
    <p>
      您的个人信息均储存于中华人民共和国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。
    </p>
    <p><b>（三）安全措施</b><b></b></p>
    <p>
      1.我们会以业界成熟的安全标准和规范收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。
    </p>
    <p>
      2.我们非常重视信息安全。我们委派了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取符合业界标准的合理可行的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
    </p>
    <p>
      3.我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
    </p>
    <p>
      4.在个人信息安全事件时，我们会立即启动应急预案，采取相应的补救措施，在最短时间内追溯原因并减少损失。与此同时，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p>
      5.<b>我们提醒您注意，互联网并非绝对安全的环境，请注意确保您个人信息的安全，请使用复杂密码，协助我们保证您的账号以及个人信息安全</b>。
    </p>
    <p><b>五、您的权利</b><b></b></p>
    <p>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
    </p>
    <p><b>（一）访问您的个人信息</b><b></b></p>
    <p>
      您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
    </p>
    <p>
      账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码，您可以通过“我的”页面进行编辑和修改。
    </p>
    <p>如果您希望关闭您的账户，您可以自行注销帐号。</p>
    <p>
      对于您在使用我们的产品或服务过程中产生的您的个人信息，只要我们不需要过多投入，我们可根据您的申请向您提供。
    </p>
    <p><b>（二）更正您的个人信息</b><b></b></p>
    <p>
      当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
    </p>
    <p>
      如果您无法通过上述链接更正这些个人信息，您可以随时致电客服热线<b>0371</b
      ><b>-56923638</b>，我们将在30天内回复您的更正请求。
    </p>
    <p><b>（三）删除您的个人信息</b><b></b></p>
    <p>您可以随时向我们提出删除个人信息的请求。</p>
    <p>
      若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
    </p>
    <p>
      当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </p>
    <p><b>（四）改变您授权同意的范围</b><b></b></p>
    <p>
      每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时关闭相应权限。
    </p>
    <p>
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p>
      如果您不想接受我们给您发送的商业广告，您可以随时致电客服热线<b>0371</b
      ><b>-56923638</b>，我们将在15天内回复您的更正请求。
    </p>
    <p><b>（五）个人信息主体注销账户</b><b></b></p>
    <p>
      您随时可注销此前注册的账户，在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
    </p>
    <p><b>（六）个人信息主体获取个人信息副本</b><b></b></p>
    <p>
      在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您。
    </p>
    <p><b>（七）约束信息系统自动决策</b><b></b></p>
    <p>
      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，或要求我们关闭对您的自动决策机制。
    </p>
    <p><b>（八）响应您的上述请求</b><b></b></p>
    <p>
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </p>
    <p>我们将在15日内答复。如您不满意，还可以通过以下途径投诉：</p>
    <p>• 人工服务电话：0371-56923638</p>
    <p>• 服务邮箱：yunji@yun-g.com</p>
    <p>我们将会在收到您投诉之后的十五日内答复及处理。</p>
    <p>
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁盘上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
    <p>1.与国家安全、国防安全有关的；</p>
    <p>2.与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3.与犯罪侦查、起诉和审判和执行等有关的；</p>
    <p>4.有充分证据表明您存在主观恶意或滥用权利的；</p>
    <p>5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
    <p><b>六、我们如何处理未成年的个人信息</b><b></b></p>
    <p>
      我们的产品、网站和服务只面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。
    </p>
    <p>
      对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关内容。
    </p>
    <p>
      尽管当地法律和习俗对儿童的定义不同，但我们将不满18周岁的任何人均视为非成年人。对于经父母或监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
    </p>
    <p><b>七、本隐私权政策如何更新</b><b></b></p>
    <p>
      <b
        >我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以推送消息的方式公布，并且标注更新时间。除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的1日后生效。如该等变更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示的方式通知您。在该种情况下，如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束</b
      >。
    </p>
    <p>
      对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
    </p>
    <p>本政策所指的重大变更包括但不限于：</p>
    <p>
      （一）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p>
      （二）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p>（三）个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>（四）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      （五）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>（六）个人信息安全影响评估报告表明存在高风险时。</p>
    <p><b>八、如何联系我们</b><b></b></p>
    <p>
      如果您对本隐私政策有任何疑问、意见或建议，可致电客服热线0371-56923639。
    </p>
    <p>名词解释：</p>
    <p>
      <b>个人信息</b
      >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、驾驶证、社保信息等）；个人生物识别信息（包括声纹、面部识别特征等）；网络身份标识信息（包括个人信息主体账号、IP地址、个人数字证书等）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码）；个人位置信息（包括行程信息、精准定位信息）。
    </p>
    <p>
      <b>设备</b>是指可用于产品和/或服务的装置，例如桌面设备、平板电脑或计算机。
    </p>
    <p>
      <b>Cookie</b
      >是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为Cookie。
    </p>
    <p>
      <b>匿名标示符</b
      >是指本公司基于设备参数，通过机器算法，生成一个标示符例如BAIDUID、CUID，帮助我们识别一个用户，为其提供更好的产品和/或服务与改善用户体验。
    </p>
    <p>
      <b>用户画像</b
      >是指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。
    </p>
    <p>
      <b>匿名化</b
      >是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。
    </p>
    <p>
      <b>本软件</b
      >是指郑州云济信息科技有限公司本软件平台及网站、程序、服务、工具及客户端。
    </p>
    <p>
      <b>关联公司</b
      >是指“本软件”的经营者郑州云济信息科技有限公司及其他与本公司存在关联关系的公司的单称或合称。“关联关系”是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。
    </p>
  </div>
</template>
<script>
export default {
  name:"AgreementYS",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow-y: scroll;
  padding: 0 0.3rem;
  height: 400px;
}
</style>